<template>
  <div>
    <template>
      <h2 class="mt-5 mb-3">
        Progresión de
        {{ $getVisibleNames("mesh.competence", true, "Competencias") }}
      </h2>

      <!-- <div v-if="user.is_professor">
        <div class="mt-2 mb-4">
          <template v-if="section_id">
            <div :class="{ 'student-carousel-sticky': window_top > 318 }">
              <div class="student-carousel">
                <span class="student-label" v-if="window_top > 318">
                  Estudiante:
                </span>
                <StudentCarousel
                  :class="{ 'ml-3': window_top > 318 }"
                  :section_id="section_id"
                  @student_selcted="slotStudentSelected"
                ></StudentCarousel>
                <div class="toggle-container p-1">
                  <div
                    v-b-tooltip.v-secondary.noninteractive.hover.top="
                      'Incluye resultados de evaluación hasta la fecha.'
                    "
                  >
                    Incluir Asignaturas Actuales
                  </div>
                  <div class="toggle-types">
                    <span :class="{ 'font-weight-bold': !checked }">Si</span>
                    <b-button
                      class="check-type"
                      @click="renderKey"
                      variant="primary"
                    >
                      <b-icon
                        icon="circle-fill"
                        class="icon-check"
                        :class="{ 'icon-check-active': checked }"
                        scale="0.7"
                      ></b-icon>
                    </b-button>
                    <span :class="{ 'font-weight-bold': checked }">No</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div v-if="selected_student_id != null && profileMatter">
          <AchievementsPerCompetenceTable
            :key="keyComponent"
            :user_id="selected_student_id"
            :checked="checked"
            :egress_profile_id="profileMatter.egress_profile"
          ></AchievementsPerCompetenceTable>
        </div>
      </div> -->
      <!-- <div v-else-if="user.is_student && selected_egress_profile"> -->
      <template v-for="egress_profile in egress_profiles_list">
        <div v-if="user.is_student" :key="egress_profile.id">
          <!-- :key="keyComponent" -->
          <AchievementsPerCompetenceTable
            :user_id="user.id"
            :checked="checked"
            :egress_profile_id="egress_profile.id"
          ></AchievementsPerCompetenceTable>
        </div>
      </template>
    </template>
    <!-- <DashboardCustomFab
      v-if="user.is_professor"
      class="noprint"
      :section_id="section_id"
      :show_achievements="false"
    ></DashboardCustomFab> -->
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import { toast } from "@/utils/utils";
export default {
  name: "AchievementsPerCompetenceView",
  components: {
    // StudentCarousel: () => import("@/components/reusable/StudentCarousel"),
    AchievementsPerCompetenceTable: () =>
      import("./AchievementsPerCompetenceTable"),
    // DashboardCustomFab: () =>
    //   import("@/components/dashboard/DashboardCustomFab"),
  },
  data() {
    return {
      // section_id: Number(this.$route.params.section_id),
      egress_profiles_list: [],
      selected_student_id: null,
      // selected_egress_profile: null,
      checked: false,
      keyComponent: 0,
      window_top: 0,
    };
  },
  computed: {
    ...mapGetters({
      // sections: names.SECTIONS,
      // egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      // egress_profiles: names.EGRESS_PROFILES,
      // matters: names.MATTERS,
      institution: "getInstitution",
      user: "getUser",
    }),
    // section() {
    //   if (!this.section_id) return [];
    //   return this.sections.find((x) => x.id == this.section_id);
    // },
    // profileMatter() {
    //   if (!this.section) return null;
    //   return this.egress_profiles_matters.find(
    //     (x) => x.id == this.section.egress_profile_matter
    //   );
    // },
  },
  methods: {
    // slotStudentSelected(student_id) {
    //   this.selected_student_id = student_id;
    //   this.keyComponent += 1;
    // },
    // renderKey() {
    //   this.checked = !this.checked;
    //   this.keyComponent += 1;
    // },
    onScroll(e) {
      this.window_top = e.target.documentElement.scrollTop;
    },
  },
  created() {
    this.$hasPermissions();
    this.$store.dispatch(names.FETCH_TAXONOMIES);
    // this.$store.dispatch(names.FETCH_EGRESS_PROFILES).then((response) => {
    //   this.egress_profiles_list = response;
    // });
    this.$restful
      .Get("/mesh/egress-profile/?filter_anyways=True")
      .then((response) => {
        this.egress_profiles_list = response;
        response.forEach((element) => {
          this.$store.dispatch(names.FETCH_PROFILE_CYCLES, {
            egress_profile_id: element.id,
          });
        });
      });
    // this.$store
    //   .dispatch(names.FETCH_SECTION, this.section_id)
    //   .then((section) => {
    //     this.$store
    //       .dispatch(
    //         names.FETCH_EGRESS_PROFILE_MATTER,
    //         section.egress_profile_matter
    //       )
    //       .then((matter) => {
    //         this.$store.dispatch(names.FETCH_MATTER, matter.matter);
    //         this.$store.dispatch(names.FETCH_PROFILE_CYCLES, {
    //           egress_profile_id: matter.egress_profile,
    //         });
    //       });
    //   });
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
<style scoped>
.student-carousel {
  display: flex;
  transform: translateY(46%);
}
.student-carousel-sticky {
  position: fixed !important;
  top: 30px;
  width: 100%;
  min-height: 1vh;
  padding-top: 4em;
  z-index: 300;
  transform: translateX(-12px);
  background: var(--primary-color);
}
.student-label {
  margin-left: 1%;
  transform: translateY(-20%);
}
.toggle-container {
  margin: 0px 0px 0px 0px;
  transform: translate(-12%, -47%);
  background: rgb(230, 230, 230);
}
.toggle-types {
  display: flex;
  float: right;
  margin-right: 1em;
  background: rgb(230, 230, 230);
  padding: 0.3em 3em;
  border-radius: 3px;
  /* transform: translate(-12%, -40%); */
}
.toggle-height {
  height: 100px !important;
}
.check-type {
  background: #007bff !important;
  margin-inline: 0.7em;
  height: 1.1em;
  width: 2.2em !important;
  margin-top: 4%;
  border-radius: 10px;
}
.icon-check {
  color: white;
  transform: translate(-70%, -40%);
  transition: 0.2s;
}
.icon-check-active {
  transform: translate(15%, -40%) !important;
}
</style>